import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate,useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';
import GPrompt from '../components/gPrompt';


function Account() {
    const navigate = useNavigate();
    const {id} = useParams();
    const { profile, token, setProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customer, setCustomer]=useState({});
    const [payments, setPayments] = useState([])
    const [level,setLevel]  =useState(null);
    const [bal, setBal] =useState(0);
    const [fundamount,setFundamount]=useState(0);
    const [sponsorCode, setSponsorCode]=useState('')
    const [uplines, setUplines]=useState([]);
    const [isFund, setIsFund] =useState(false);
    const [errmsg, setErrmsg]=useState('');
    

    //load profile info.
  
    const [loading, setLoading] =useState(false);
 //{ code = 0, customers, payments };


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, [id]);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/customer/account`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               
               setPayments(data.payments);
               setBal(data.bal);
            
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    


  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div >
<GCustomerHeader active="account" />      
    <div className='cusBody'>
<h2>Account Balance: {bal}</h2>  
   
    <div className='innerBox'>
     <div style={{flex:1}}>
      <h3>Payments</h3>
      <table>
        <tr>
          <th>Pay Ref</th>
          <th>Amount</th>
          <th>Game </th>
          <th>Note</th>
          <th>Source</th>
          
        </tr>
        
        {payments?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.refId}</td>
                            <td>{cu.amount}</td>
                           <td>{cu.gameId}</td>
                           <td>{cu.note}</td>
                           
                            <td>{cu.source}</td>
                    </tr>
                  )})}
      </table>
     </div>
    
    </div>
 
    </div>

</div>

    </div>

  )
}

export default Account