import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';


function Leaders() {
    const navigate = useNavigate();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customers, setCustomers]=useState([]);
    const [fcustomers, setFCustomers]=useState([]);
    const [count,setCount]  =useState(0);
    //load profile info.
  
    const [loading, setLoading] =useState(false);
    const [searchAg,setSearchAg] =useState('');
 


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/admin/leaders`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               setCustomers(data.customers);
               setFCustomers(data.customers);
               setCount(data.totalcost);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
     const onDetails =(g)=>{
      navigate(`/admin/customers/${g.id}`)
         }
    
         const onSearchAg=(value)=>{
          
              if(value!=''){
              const filteredData_ = customers?.filter(item => {
                const searchMatch = item.firstname.toLowerCase().includes(value.toLowerCase()) || (item.lastname?.toLowerCase().includes(value.toLowerCase())) || item.phone.toLowerCase().includes(value.toLowerCase())
                
                return searchMatch 
            })
            setFCustomers(filteredData_);
            }else{
              setFCustomers(customers);
            }
          
          
          
          setSearchAg(value);
      }
      
      const onServerSearch=async()=>{
 
        var urls=`${Constants.rooturl}/admin/searchleader/${searchAg}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
  
            if (data.code === 0) {
              setCustomers(data.customers);
              setFCustomers(data.customers);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     
    }

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div className='topAdmin'>
            <GAdminSidebar active="leaders" />
    <div className='contentside'>
    <GAdminHeader />
    <div className='innerBox'>
     <div  style={{width:'80%'}}>
     <div >
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                           <GInput type="text" placeholder='Search Leader' value={searchAg} onChange={(e) =>onSearchAg(e.target.value) }/>
 <button className='btn_primary' onClick={onServerSearch} >Search</button>
                        </div>
                        <h2>Total Number: {count}</h2>
      <table>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Phone </th>
          <th>Email</th>
          <th>Sponsor Code</th>
          <th>Action</th>
        </tr>
        
        {fcustomers?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.firstname}</td>
                            <td>{cu.lastname}</td>
                           <td>{cu.phone}</td>
                           <td>{cu.email}</td>
                           <td>{cu.sponsorCode}</td>
                           
                            <td><button className='btn_sec' onClick={()=>onDetails(cu)}>Details</button></td>
                    </tr>
                  )})}
      </table>
     </div>
    </div>
    
  
    </div>

</div>

    </div>

  )
}

export default Leaders