import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck, faSquare,  faEye, faEyeSlash, faCheckCircle} from '@fortawesome/free-regular-svg-icons';


import GInput from '../components/gInput';
import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GHeader from '../partials/gHeader';
import { faAt, faDotCircle, faKey,faMessage,faPhone,faUser } from '@fortawesome/free-solid-svg-icons';

function Register() {
    const navigate = useNavigate();
    const { profile , setProfile, setToken} = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [showpass, setShowPass] =useState(false);
    const [checkrem, setChechrem] =useState(false);

    const [password, setPassword]=useState('');
    const [email, setEmail]=useState('');
    const [phone, setPhone]=useState('');
    const [firstname, setFirstname]=useState('');
    const [lastname, setLastname] =useState('');
    const [referal,setReferal] =useState('');
    const [isreferal, setIsreferal]=useState(false);
    const [referalname, setReferalname] = useState('');

    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    /* const onLogin=()=>{
        navigate('/dashboard');
    } */
        useEffect(() => {
          setProfile({});
          setToken('');
            }, []);
    const onLogin=async(e)=>{
      e.preventDefault(); 
        setLoading(true);
        
        try {
          var v={firstname,lastname,phone,email, password, status:'active', referalcode:referal };
          const resp = await fetch(`${Constants.rooturl}/game/customer`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
            setToken(data.token);
         
            var res={...v,id:data.id};
            setProfile(res);
            //save both token and profile on storage and set current date, so you can compare the date during refresh
           localStorage.setItem("profile",JSON.stringify(res) )
           localStorage.setItem("token",data.token);
           localStorage.setItem("tokendate", new Date().getTime().toString()); //this is keeping the date to try to relunch token or not

            navigate('/dashboard');
          } else if (data.code === -1){
            setErr('your username/password not correct. Try again');
          }
          else {   
            setErr(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }

const onCheck=async()=>{
  var urls=`${Constants.rooturl}/game/referal/check/${referal}`;
  try {
      setLoading(true);
      const resp = await fetch(urls, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': '',
          
        },
      });
      
      const data = await resp.json();
    
      setLoading(false);
      console.log(data);
      if (data.code === 0) {
         setIsreferal(true);
         setReferalname(`Your Sponsor: ${data.firstname} ${data.lastname}`)
      }else {    
        console.error(data.status);
        setIsreferal(false);
        setReferalname('The Referal code is not correct.')
      }
    } catch (error) {
      setLoading(false);
      console.error(error); 
    }
}

  return (
    <div className='appWrap'>
        <div>
          
          <GHeader active="login" />
       <div >
            <div className='wrapper'>
                <div style={{textAlign:'center'}}>
                    <img src={Logo} />
                    <h2>TWO LEAVES GAMES</h2>
                    <h3>Create an Account</h3>
                    <p>Enter your your details to continue</p>
                </div>
                <form onSubmit={onLogin}>
                    
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faUser}  />}
                    placeholder="First Name" value={firstname} onChange={(e)=>setFirstname(e.target.value)} maxLength={50}  required />
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faUser}  />}
                    placeholder="Last Name" value={lastname} onChange={(e)=>setLastname(e.target.value)} maxLength={50}  required />
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faPhone}  />}
                    placeholder="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} maxLength={20} required />
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faAt}  />}
                    placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} maxLength={50}  />
                    
                    <GInput 
                    leftIcon={<FontAwesomeIcon icon={faKey}  />}
                    placeholder="Password" type={showpass?'text':"password"} maxLength={50} minLength={5} value={password} onChange={(e)=>setPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash } onClick={()=>setShowPass(!showpass)} />} required />

                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faAt}  />}
                    placeholder="Referal Code" value={referal} onChange={(e)=>{setReferal(e.target.value); setReferalname('');setIsreferal(false);}} maxLength={50}
                    rightIcon={<FontAwesomeIcon icon={!isreferal? faDotCircle:faCheckCircle} title="Click to confirm referal code" onClick={onCheck}  />}  />
                   {referal=='' && <p style={{fontWeight:'bold', color:'red', textAlign:')center'}}>Please indicate your referal code. If not the system will use company code.
                     Please Click the circle Icon next to Referal Code to check if valid
                     </p>} 
                    {referal!='' && referalname!=null && <p style={{color:isreferal? 'green':'red', textAlign:'center', fontWeight:'bold'}}>{referalname}</p>} 
                    {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='btn_primary' style={{width:'100%'}} type="submit">Sign Up</button>
                        </div>         
                        
                              </div>} 
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                    <div style={{marginBottom:16}}><span>You already have an account? <Link to={'/login'}> Log In</Link></span></div>
                </form>
                
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default Register