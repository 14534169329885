import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';


function Games() {
    const navigate = useNavigate();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [games, setGames]=useState([]);
    const [level,setLevel]  =useState(null);
    const [game, setGame] = useState({date:new Date(),time:'0:00:00',amount:200})
    //load profile info.
  
    const [loading, setLoading] =useState(false);
 


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/all`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               setGames(data.games);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
   const onSaveGame=async(e)=>{
    e.preventDefault(); 
      setLoading(true);
     console.log(game);
     
      try {
        var v={startTime:`${game.date} ${game.time}`, status:'active', amount:game.amount};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': adminToken,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
          ////just re load
          Load();
           
        } 
      } catch (error) {
        setLoading(false);
        console.error(error);
       // setErr(Constants.serverErr);
      
       // onMove();
      }
   
   }
   const onDetails =(g)=>{
navigate(`/admin/games/${g.id}`)
   }

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div className='topAdmin'>
            <GAdminSidebar active="games" />
    <div className='contentside'>
    <GAdminHeader />
    <div className='innerBox'>
      <div style={{width:'80%'}}>
        <h3>Create New Game</h3>
        <form onSubmit={onSaveGame}>
          <div style={{display:'flex', flexDirection:'row'}}>
            <GInput placeholder="Date of Game" value={game.date } type='date' onChange={(e)=>setGame({...game ,date: e.target.value})} />
            <GInput placeholder='Time' value={game.time} onChange={(e)=>setGame({...game ,time: e.target.value})} type='time' required  />
            
            
          </div>
          <GInput placeholder='Game Amount' value={game.amount}  onChange={(e)=>setGame({...game ,amount: e.target.value})} required />
          <button className='btn_primary' type='submit'>Save Game</button>
        </form>
        
        <table>
            <tr>
                <th>Game No</th>
                <th>Start Date</th>
                <th> Time</th>
                <th>Status</th>
           {/*      <th>Level</th>
                <th>Member</th> */}
                <th>Action</th>

            </tr>

            {games?.map((g, idx) =>{return (
                    <tr key={idx}>
                            <td>Game {g.id}</td>
                            <td>{Constants.formatDate(g.startTime) }</td>
                            <td>{Constants.formatTime(g.startTime) }</td>
                            <td>{g.status}</td>
                            <td><button className='btn_sec' onClick={()=>onDetails(g)}>Details</button></td>
                    </tr>
                  )})}
        </table>
      </div>
        
    </div>
    
  
    </div>

</div>

    </div>

  )
}

export default Games