import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate,useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';

function GameDetails() {
    const navigate = useNavigate();
    const {id} = useParams();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [game, setGame]=useState({});
    const [levels, setLevels]=useState([]);
    const [level, setLevel]=useState([]);
    const [currentlevel,setCurrentLevel]  =useState({});
    const [pairs,setPairs]=useState([]);
    const [interested,setInterested]=useState([]);
    //load profile info.
  
    const [loading, setLoading] =useState(false);
    


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/all/${id}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               //{ code = 0, game, levels, currentlevel, pairs, interested };
               setGame(data.game);
               setLevels(data.levels);
               setCurrentLevel(data.currentlevel);
               setPairs(data.pairs);
               setInterested(data.interested);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    

     const onSaveLevel=async(e)=>{
        e.preventDefault(); 
          setLoading(true);
         console.log(game);
         
          try {
            var v={startTime:`${level.date} ${level.time}`, status:'active',
             cashoutAmount:level.cashout,gameId:game.id, levelNo: level.levelno, expireSec:30 };//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
            const resp = await fetch(`${Constants.rooturl}/game/nextlevel`, {
              method: 'POST',
              body:JSON.stringify(v) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': adminToken,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
              ////just re load
              Load();
               
            } 
          } catch (error) {
            setLoading(false);
            console.error(error);
           // setErr(Constants.serverErr);
          
           // onMove();
          }
       
       }

       const onLevelAction=async(lev)=>{

        var endpoint=''
        var obj={levelId:lev.id, gameId:id};
        if(lev.status=='active'){
          endpoint='start';
        }else if(lev.status=='ongoing'){
          endpoint='stop'
        }
          setLoading(true);
         console.log(game);
         
          try {
            //{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
            const resp = await fetch(`${Constants.rooturl}/game/${endpoint}`, {
              method: 'POST',
              body:JSON.stringify(obj) ,
              headers: {
                'Content-Type': 'application/json, text/plain',
                'Authorization': adminToken,
              },
            });
            
            const data = await resp.json();
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
              ////just re load
              Load();
               
            } 
          } catch (error) {
            setLoading(false);
            console.error(error);
           // setErr(Constants.serverErr);
          
           // onMove();
          }

       }

       const onCashout=async()=>{
            
    
        setLoading(true);
       
        try {
          var v={levelId:currentlevel.id, gameId:id}; //+1 include the present one about to call
          const resp = await fetch(`${Constants.rooturl}/game/cashout/force`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': adminToken,
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
            //on success, show dialog and msg
            alert('This game is over. You will have to create Another game. force Cashout completed')
            navigate('/admin/games')
          } 
        } catch (error) {
          setLoading(false);
          console.error(error);
        
         // onMove();
        }
     }
    

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div className='topAdmin'>
            <GAdminSidebar active="customers" />
    <div className='contentside'>
    <GAdminHeader />
    <div>
        <h3>Create New Level</h3>
        <p>note this will require that you end the current one</p>
      <div className='innerBox'>
      <form onSubmit={onSaveLevel}>
          <div style={{display:'flex', flexDirection:'row'}}>
            <GInput placeholder="Date of Game" value={level.date } type='date' onChange={(e)=>setLevel({...level ,date: e.target.value})} />
            <GInput placeholder='Time' value={level.time} onChange={(e)=>setLevel({...level ,time: e.target.value})} type='time' required  />
            
            
          </div>
          <GInput placeholder='Cashout Amount' value={level.cashout}  onChange={(e)=>setLevel({...level ,cashout: e.target.value})} required />
          <GInput placeholder='levelno' value={level.levelno} type="number"  onChange={(e)=>setLevel({...level ,levelno: e.target.value})} required />
          <button className='btn_primary' type='submit'>Save Level</button>
        </form>
    </div>  
    </div>
    
    <div className='innerBox' >
      <div>
         <p>End the Entire game, meaning, the system will cashout for all other member. The cashout amount on last level played goes into their respective wallet.
          <br/>
          <span style={{color:'red'}}>This process is not reversible</span>
         </p>
         <button className='btn_pry_red' type='button' onClick={onCashout}>Terminate the Game. Force cashout</button>
      </div>
     
    </div>
    <div className='innerBox'>
     <div style={{flex:1}} >
      <table>
        <tr>
          <th>Game No</th>
          <th>Level No</th>
          <th>Game Date</th>
          <th>Start time </th>
          <th>Cashout Amount</th>
          
          <th>Status</th>
          <th>Action</th>
        </tr>
        
        {levels?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.gameId}</td>
                            <td>{cu.levelNo}</td>
                           <td>{Constants.formatDate(cu.startTime) }</td>
                           <td>{Constants.formatTime(cu.startTime) }</td>
                           <td>{cu.cashoutAmount}</td>
                           <td>{cu.status}</td>
                           
                           
                            <td> {cu.isCurrent ? <button  className='btn_sec' onClick={()=>onLevelAction(cu)}>{cu.status=='active'?'Start Game':(cu.status=='ongoing' && 'End Level')}</button>:<span>Level Completed</span>}</td>
                    </tr>
                  )})}
      </table>
     </div>
     <div style={{flex:1}}>
      <h3>Interested Customers</h3>
      <h2>Total Number: {interested.length}</h2>
      <table>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Phone </th>
          <th>Amount</th>
          <th>LevelNo</th>
          <th>Status</th>
          
        </tr>
        
        {interested?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.firstname}</td>
                            <td>{cu.lastname}</td>
                           <td>{cu.phone}</td>
                           <td>{cu.amount}</td>
                           <td>{cu.levelNo}</td>
                           <th>{cu.status}</th>
                            
                    </tr>
                  )})}
      </table>
     </div>
     
    </div>
    
  
    </div>

</div>

    </div>

  )
}

export default GameDetails