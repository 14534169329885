import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck, faSquare,  faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';


import GInput from '../components/gInput';
import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GHeader from '../partials/gHeader';
import { faAt, faKey,faMessage,faPhone,faUser } from '@fortawesome/free-solid-svg-icons';

function AdminUser() {
    const navigate = useNavigate();
    const { adminProfile , setAdminProfile, adminToken} = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [showpass, setShowPass] =useState(false);
    const [checkrem, setChechrem] =useState(false);

    const [password, setPassword]=useState('');
    const [email, setEmail]=useState('');
   
    const [firstname, setFirstname]=useState('');
    const [lastname, setLastname] =useState('');

    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    /* const onLogin=()=>{
        navigate('/dashboard');
    } */
        useEffect(() => {
          
            }, []);
    const onLogin=async(e)=>{
      e.preventDefault(); 
        setLoading(true);
        
        try {
          var v={firstname,lastname,email, password, status:'active'};
          const resp = await fetch(`${Constants.rooturl}/admin/user`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': adminToken,
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
          
            navigate('/admin/login');
          } else if (data.code === -1){
            setErr('your username/password not correct. Try again');
          }
          else {   
            setErr(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }


  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
          
          <GHeader active="login" />
       <div >
            <div className='wrapper'>
                <div style={{textAlign:'center'}}>
                    <img src={Logo} />
                    <h2>TWO LEAVES GAMES</h2>
                    <h3>Create an Account</h3>
                    <p>Enter your your details to continue</p>
                </div>
                <form onSubmit={onLogin}>
                    
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faUser}  />}
                    placeholder="First Name" value={firstname} onChange={(e)=>setFirstname(e.target.value)} maxLength={50}  required />
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faUser}  />}
                    placeholder="Last Name" value={lastname} onChange={(e)=>setLastname(e.target.value)} maxLength={50}  required />
                    
                    <GInput
                    leftIcon={<FontAwesomeIcon icon={faAt}  />}
                    placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} maxLength={50} type="email"  />
                    
                    <GInput 
                    leftIcon={<FontAwesomeIcon icon={faKey}  />}
                    placeholder="Password" type={showpass?'text':"password"} maxLength={50} minLength={5} value={password} onChange={(e)=>setPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash } onClick={()=>setShowPass(!showpass)} />} required />

                   
                    
                    {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='btn_primary' style={{width:'100%'}} type="submit">Register Admin User</button>
                        </div>         
                        
                              </div>} 
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                    
                </form>
                
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default AdminUser