import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';
import GPrompt from '../components/gPrompt';

function Downline() {
    const navigate = useNavigate();
    const { profile, token, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customers, setCustomers]=useState([]);
    const [customer, setCustomer]=useState({});
    const [fcustomers, setFCustomers]=useState([]);
    const [count,setCount]  =useState(0);
    const [level,setLevel]  =useState(null);
    const [fundamount,setFundAmount] =useState(0);
    //load profile info.
  
    const [loading, setLoading] =useState(false);
    const [searchAg,setSearchAg] =useState('');
    const [isFund, setIsFund] =useState(false);
    const [errmsg, setErrmsg]=useState('');


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/leader/downline`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               setCustomers(data.customers);
               setFCustomers(data.customers);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
     const onSendFund=async()=>{
      setLoading(true);
     
      
       try {
         var v={customerId:customer.id, amount:fundamount};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
         const resp = await fetch(`${Constants.rooturl}/game/sponsor/fundwallet`, {
           method: 'POST',
           body:JSON.stringify(v) ,
           headers: {
             'Content-Type': 'application/json, text/plain',
             'Authorization': token,
           },
         });
         
         const data = await resp.json();
         setLoading(false);
         console.log(data);
         if (data.code === 0) {
           ////just re load
           Load();
            
         } else{
          setErrmsg(data.status);
         }
       } catch (error) {
         setLoading(false);
         console.error(error);
        // setErr(Constants.serverErr);
       
        // onMove();
       }
  }
  
  const PickFund=(e)=>{
      setCustomer(e);
      setIsFund(true);
  }
    


         const onSearchAg=(value)=>{
          
          if(value!=''){
          const filteredData_ = customers?.filter(item => {
            const searchMatch = item.firstname.toLowerCase().includes(value.toLowerCase()) || (item.lastname?.toLowerCase().includes(value.toLowerCase())) || item.phone.toLowerCase().includes(value.toLowerCase())
            
            return searchMatch 
        })
        setFCustomers(filteredData_);
        }else{
          setFCustomers(customers);
        }
      
      
      
      setSearchAg(value);
  }
  
  const onServerSearch=async()=>{

    var urls=`${Constants.rooturl}/game/search/downline/${searchAg}`;
    try {
        setLoading(true);
        const resp = await fetch(urls, { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            
          },
        });
        
        const data = await resp.json();
      
        setLoading(false);
        console.log(data);

        if (data.code === 0) {
          setCustomers(data.customers);
          setFCustomers(data.customers);
          setCount(data.count);
        }else {    
          console.error(data.status);
        }
      } catch (error) {
        setLoading(false);
        console.error(error); 
      }
 
}
  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div >
<GCustomerHeader active="account" />      
<div className='cusBody'>
    <GAdminHeader />
    <div className='innerBox'>
     <div  style={{width:'80%'}}>
     <div >
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                           <GInput type="text" placeholder='Search Customer' value={searchAg} onChange={(e) =>onSearchAg(e.target.value) }/>
 <button className='btn_primary' onClick={onServerSearch} >Search</button>
                        </div>
                        <h2>Total Number: {count}</h2>
      <table>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Phone </th>
          <th>Email</th>
          <th>Referal Code</th>
          <th>Action</th>
        </tr>
        
        {fcustomers?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.firstname}</td>
                            <td>{cu.lastname}</td>
                           <td>{cu.phone}</td>
                           <td>{cu.email}</td>
                           <td>{cu.referalcode}</td>
                           
                            <td><button className='btn_sec' onClick={()=>PickFund(cu)}>Fund Account</button></td>
                    </tr>
                  )})}
      </table>
     </div>
    </div>
    
  
    </div>

</div>
<GPrompt open={isFund} text={`You are about to fund the wallet of ${customer?.firstname} ${customer.lastname}. The Funding will not e reversable.`}
 onProceed={onSendFund} onCancel={()=> setIsFund(false)} errmsg={errmsg} loading={loading}>

<div>
        <h3>Fund Customer Account</h3>
      <GInput placeholder="Please enter the amount" value={fundamount}  onChange={(e)=>setFundAmount(e.target.value)} /> 
        
      </div>
 </GPrompt>

    </div>

  )
}

export default Downline